import React, { useState, useEffect } from "react";
// import axios from "axios";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import Footer from "../components/footer";
import Nav from "../components/nav";
import SEO from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface ProductInterface {
  data: any;
  pageContext: any;
}

const Product: React.FC<ProductInterface> = ({ data, pageContext }) => {
  const allProducts = data.allProduct.nodes;
  const title = `${allProducts[0].topup_title} ${pageContext.id} via PayPal`;
  const rate = 14200;
  return (
    <>
      <SEO title={title}>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Home",
    "item": "https://rupiah.trade"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "Produk",
    "item": "https://rupiah.trade/product"  
  },{
    "@type": "ListItem", 
    "position": 3, 
    "name": "${pageContext.id}",
    "item": "https://rupiah.trade/product/${kebabCase(pageContext.id)}"  
  }]
}`}
        </script>
      </SEO>
      <Nav />

      <nav className="mt-4 lg:mt-8 px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <ol
          className="inline text-sm text-slate-500 dark:text-slate-400"
          id="breadcumb"
        >
          <li className="inline after:content-['/'] after:px-2">
            <Link
              className="font-semibold text-primary-700 hover:text-primary-900 "
              to="/"
            >
              <span>Beranda</span>
            </Link>
          </li>
          <li className="inline after:content-['/'] after:px-2">
            <Link
              className="font-semibold text-primary-700 hover:text-primary-900 "
              to="/product"
            >
              <span>Produk</span>
            </Link>
          </li>

          <li className="inline">
            <span>{pageContext.id}</span>
          </li>
        </ol>
      </nav>
      <div
        data-aos="fade-up"
        className="px-4 mt-8 pb-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
      >
        <h1 className=" text-3xl font-bold text-gray-900">
          <span className="text-primary-700">{title}</span> di Rupiah.trade
        </h1>
      </div>
      <div
        data-aos="fade-up"
        className="px-4 pb-16 mt-8 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20  grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-6"
      >
        {allProducts.map((el: any) => {
          const usd_price = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "USD",
            maximumSignificantDigits: 5,
          }).format(Number(rateUSD(el.product_price, rate)));
          return (
            <Link
              to={`/product/${kebabCase(pageContext.id)}/${kebabCase(
                el.product_nominal
              )}`}
              key={el.id}
              role="button"
            >
              <div className="bg-gray-100 shadow-sm rounded-xl p-4 overflow-hidden hover:shadow-2xl hover:bg-slate-50">
                <GatsbyImage
                  className="-m-4 mb-4"
                  image={getImage(el.featuredImage)}
                  alt={title}
                />
                <h2 className="font-semibold">{el.product_nominal}</h2>
                <p className=" text-purple-700">{usd_price}</p>
              </div>
            </Link>
          );
        })}
      </div>
      <Footer />
    </>
  );
};

export default Product;

const rateUSD = (total: number, rate: number) => {
  const paypalRate = rate - rate * 0.04;
  const fee = (total / paypalRate) * 0.039 + 0.3;

  let res = total / (paypalRate * 0.87) + fee;
  if (total / paypalRate > 9 && total / paypalRate < 50) {
    res = total / (paypalRate * 0.89) + fee;
  }
  if (total / paypalRate >= 50) {
    res = total / (paypalRate * 0.94) + fee;
  }
  if (!total) {
    res = 0;
  }

  return Number(res).toPrecision(2);
};

export const categoryQuery = graphql`
  query ProductCategoryQuery($id: String) {
    allProduct(filter: { product_description: { eq: $id } }) {
      nodes {
        id
        status
        title
        topup_title
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: WEBP
              breakpoints: [300, 500, 600]
            )
          }
        }
        product_price
        product_nominal
      }
    }
  }
`;
